<template>
  <div>
    <b-card no-body>
      <b-card header-tag="header">
        <template v-slot:header>
          <product-form-nav
            link="seolinks"
            :productDetails="productDetails"
            :productId="id"
          ></product-form-nav>
        </template>

        <form class="form" novalidate="novalidate" id="product_link">
          <div v-for="(field, i) in form" :key="i">
            <div class="d-flex align-items-center mb-10">
              <div class="symbol symbol-40 mr-5 symbol-light-primary">
                <span class="symbol-label"
                  ><span class="svg-icon svg-icon-lg svg-icon-primary">
                    <i class="las la-file-alt"></i> </span
                ></span>
              </div>
              <div class="d-flex flex-column font-weight-bold">
                <a class="text-dark text-hover-primary mb-1 font-size-lg"
                  >Section {{ i + 1 }}
                </a>
              </div>
            </div>
            <b-row class="align-items-center">
              <b-col lg="6" md="6">
                <b-form-group label-for="input-1">
                  <label
                    >Title <sup class="text-danger font-weight-boldest">*</sup></label
                  >
                  <b-form-input
                    v-model="field.title"
                    type="text"
                    class="form-control"
                    placeholder="EG: Section"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <!-- <b-col lg="6" md="6">
                <b-form-group label-for="input-1">
                  <label
                    >Sort Order
                    <sup class="text-danger font-weight-boldest">*</sup></label
                  >
                  <b-form-input
                    v-model="field.sort_order"
                    type="number"
                    name="sort"
                    class="form-control"
                  ></b-form-input>
                </b-form-group>
              </b-col> -->
            </b-row>
            <b-row
              class="align-items-center"
              v-for="(link, l) in field.links"
              :key="`link-${l}`"
            >
              <b-col lg="6" md="6">
                <b-form-group label-for="input-1">
                  <label
                    >Link Title
                    <sup class="text-danger font-weight-boldest">*</sup></label
                  >
                  <b-form-input
                    v-model="link.link_title"
                    type="text"
                    name="answer"
                    class="form-control"
                    placeholder="EG: Fiona"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6">
                <b-form-group label-for="input-1">
                  <label>Link <sup class="text-danger font-weight-boldest">*</sup></label>
                  <b-form-input
                    v-model="link.link_url"
                    type="text"
                    class="form-control"
                    placeholder="EG: https://something.com"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col class="text-right">
              <button
                ref="submit_faq"
                class="btn btn-sm btn-outline-primary font-size-h6"
              >
                <i class="las la-save"></i> Save
              </button>
            </b-col>
          </b-row>
        </form>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import ProductFormNav from "./components/ProductFormNav.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";

import ProductService from "@/core/services/api/product/product";
import internalLinkService from "@/core/services/api/product/internalLinks";

export default {
  components: {
    ProductFormNav,
  },
  data() {
    return {
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      form: [
        {
          title: "",
          sort_order: 0,
          links: [
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
          ],
        },
        {
          title: "",
          sort_order: 0,
          links: [
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
          ],
        },
        {
          title: "",
          sort_order: 0,
          links: [
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
            {
              link_title: "",
              link_url: "",
            },
          ],
        },
      ],
      productDetails: {},
    };
  },
  computed: {},
  created() {
    this.getProductById();
    this.getLinks();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Products",
        route: "/products",
      },
      {
        title: "Manage Product : Data",
      },
    ]);
    const signin_form = KTUtil.getById("product_link");
    this.fv = formValidation(signin_form, {
      fields: {
        question: {
          validators: {
            notEmpty: {
              message: "Question is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      const submitButton = this.$refs["submit_faq"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right", "disabled");
      this.createInternalLinks(function () {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right",
          "disabled"
        );
      });
    });
  },
  methods: {
    async getProductById() {
      var response = await ProductService.getProductById(this.id);
      this.productDetails = response;
    },
    getLinks() {
      var vm = this;
      internalLinkService.get(this.id).then(function (response) {
        response.map((x, i) => {
          vm.form[i].title = x[0].group_title;
          x.map((e, j) => {
            vm.form[i].links[j].link_title = e.link_title;
            vm.form[i].links[j].link_url = e.url;
          });
        });
      });
    },
    createInternalLinks(callback) {
      var vm = this;
      internalLinkService
        .create(vm.id, vm.form)
        .then(function (response) {
          vm.$router.push({ name: "product-form-internal-link", param: { id: vm.id } });
        })
        .catch(function (error) {
          callback();
        });
    },
  },
};
</script>

<style></style>
